module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.kelliepetersen.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kellie Petersen Portfolio","short_name":"KPetersen","start_url":"/","background_color":"#1c1c1c","theme_color":"#1c1c1c","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33a624be44b2a57835a57e9917413dca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
